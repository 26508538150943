import axios from 'axios';

const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 50000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
})

// 拦截请求
service.interceptors.request.use(function(config){
    config.headers={
        "Authorization":"Bearer "+localStorage.getItem('token')??"",
        // "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        
        "Content-Type": "application/json;charset=utf-8",
    };
    // config.data={
    //     ...config.data
    // }
    return config;
},function(error){
    return Promise.reject(error);
})

// 拦截响应
service.interceptors.response.use(function(response){
    // console.log(response['headers']['content-type']);
    if(response['headers']['content-type']=="application/octet-stream"){
        return response['data'];
    }else{
        // if(response['data']['code']!=200){
        //     console.log("拦截响应",response['data']['code']);
        //     if(response['data']['code']==401){
        //         // localStorage.clear();
        //     }
        //     // this.$message.error(response['data']['message']);
        // }else{
        //     return response['data'];
        // }
        if(response['data']['code']==9999){
            window.location.href="/auth/login";
        }
        return response['data'];
    }
    
},error=>{
    // console.log('服务器出错')
    // console.log(error.response.status);
    // console.log(error['response']['data']['message']);
    // switch(error.response.status){
    //     case 401:
            
    // }
    console.log(error['response']['data']['code']);
    // Message.error(error['response']['data']['message']);
    if(error['response']['data']['code']==401){
        // localStorage.clear();
    }
    // this.$message.error(error['response']['data']['message']);
    // localStorage.clear();
    return Promise.reject(error)
})

export default service;
import request from '../utils/request';

const descrypt={
    descryptWeixinService(code: any){
        return request({
            url:`weixin?code=${code}`,
            method:"GET",
        })
    },
    descryptUrlService(code: any){
        return request({
            url:`url?code=${code}`,
            method:"GET",
        })
    },
    descryptTextService(code: any){
        return request({
            url:`text?code=${code}`,
            method:"GET",
        })
    },
    descryptMailService(code: any){
        return request({
            url:`mail?code=${code}`,
            method:"GET",
        })
    }
}
export default descrypt;
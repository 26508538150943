import UserMain from '../views/user/main.vue';

export default[
    {
        path:"/user",
        component:UserMain,
        children:[
            {
                path:"list",
                component: () => import( /* webpackChunkName: "about" */ '../views/user/list.vue')
            }
        ]
    }
]
import request from '../utils/request';

const text={
    getTextListService(search: any,page: any,size: any){
        return request({
            url:`text/list?page=${page}&size=${size}&search=${search}`,
            method:'get',
        })
    },
    deleteService(id: any){
        return request({
            url:`text?id=${id}`,
            method:"delete",
        })
    }
}
export default text;
import request from "../utils/request";

const auth={
    // 登录
    loginService(data: any){
        return request({
            url:`/user/login`,
            method:"post",
            data:data
        })
    },
    // 注册
    registerService(data: any){
        return request({
            url:`/user/register`,
            method:'post',
            data:data
        })
    },
    //
    getUserInfoService(){
        return request({
            url:`/user`,
            method:"get"
        })
    },
    getEmailSuffixService(){
        return request({
            url:`/mail/suffix`,
            method:"get",
        })
    }
}

export default auth;
import generate from "./generate";
import descrypt from './descrypt';
import auth from './auth';
import text from './text';
import common from './common';
export default{
    generate,
    descrypt,
    auth,
    text,
    common,
}
<template>
  <v-app>
    <v-app-bar app elevation="0">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <!-- <v-btn icon @click="navigateToHome">
                <v-icon>mdi-home</v-icon>
            </v-btn> -->
      <div style="cursor:pointer" @click="navigateTo('/')">B64Share</div>
      <!-- <v-toolbar-title>Title</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <!-- <v-btn icon v-for="(item, i) in items" :key="i" @click="operateMore(item)">
                <v-icon>{{item.icon}}</v-icon>
            </v-btn> -->
      <v-btn style="margin-right:10px;" color="primary" @click="feedback">反馈</v-btn>
      <v-btn v-if="userCode!=0" outlined color="primary" @click="navigateTo('/auth/login')">
        登录
      </v-btn>
      <v-btn v-if="userCode==0" outlined color="primary" @click="navigateTo('/user/list')">
        管理
      </v-btn>
    </v-app-bar>
    <v-main>
      <!-- 为应用程序提供正确的插槽 -->
      <v-container fluid>
        <!-- 如果使用vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer>
      <div style="display:flex;justify-content:center;width:100%;">
        <a href="https://b64s.uk/about.html" target="_blank" style="text-decoration:none;">关于B64Share</a>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    data() {
      return {
        userCode: 1,
      }
    },
    methods: {
      feedback() {
        window.open("https://support.qq.com/products/385070");
      },
      navigateTo(path) {
        this.$router.push(path).catch(err => {
          err
        })
      },
      getUserInfo() {
        this.$service.auth.getUserInfoService()
          .then(res => {
            console.log(res);
            this.userCode = res['code'];
          })
      }
    },
    mounted() {
      this.getUserInfo();
    }
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  .message-card {
    padding: 30px;
  }

  @media screen and (max-width: 425px) {
    .message-card {
      padding: 10px;
    }
  }

  /* Helper classes */
  /* .basil {
    background-color: #FFFBE6 !important;
  }

  .basil--text {
    color: #356859 !important;
  } */
</style>
import request from '../utils/request';

const generate={
    generateWeixinService(data: any){
        return request({
            url:`weixin`,
            method:"PUT",
            data:data,
        })
    },
    generateUrlService(data: any){
        return request({
            url:`url`,
            method:"PUT",
            data:data,
        })
    },
    generateTextService(data: any){
        return request({
            url:`text`,
            method:"PUT",
            data:data,
        })
    },
    generateMailService(data: any){
        return request({
            url:`mail`,
            method:"PUT",
            data:data,
        })
    }
}
export default generate;
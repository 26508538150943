import request from "../utils/request";

const common={
    getExpireListService(){
        return request({
            url:`/expire-set`,
            method:'get',
        })
    }
}
export default common;